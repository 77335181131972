import React, { useEffect, useState } from "react";
import bubbleGame from "../../assets/images/HomePageGameImage.svg";
import { marqueeTextIteration } from "../../utils/constants";
import BulbIcon from "./../../assets/images/bulb.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import useStashLinkHooks from "../campaign/hooks/useStashLink";
import Loader from "../../components/common/loader/loader";
import MarqueeComponent from "../../components/common/marquee/marqueeComponent";
import { FaHeart } from "react-icons/fa6";
import StashrunLogo from "./../../assets/images/Stashrun-beta-logo.svg";
import mixpanel from "../../services/mixpanelService";
import { generateCloudinaryImageUrl, getLocalStorage } from "../../utils/common-function";
import Button from "../../components/common/button";
import Signature from "../../assets/images/signature.svg";
import FollowingCheck from "../../assets/images/following-check.svg";
import useCampaignFunded from "../campaign/hooks/useCampaignFunded ";
import WaitlistModal from "../../components/waitlistModal";
import { envVariables } from "../../common/constants";
import useColinkExist from "../../services/hooks/useColinkExist";
import { routes } from "../../routes/routes.constant";
import useAuthStatus from "../campaign/hooks/useAuthStatus";

const NewHomePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { userName?: string; followerProfileImage?: string; param?: any };
  const { getStashLink, stashlink, stashlinkLoading } = useStashLinkHooks();
  const [isWaitlistModalOpen, setWaitlistModalOpen] = useState<boolean>(false);
  const user = stashlink?.user;
  const campaign = stashlink?.campaign;
  const isCampaignFunded = useCampaignFunded(campaign);
  const isUserJoinedWaitlist = getLocalStorage("isUserJoinedWaitlist");
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const { colinkExistLoading, colinkExistData } = useColinkExist(stashlink?.id || "");
  const { isUserLoggedIn } = useAuthStatus();

  useEffect(() => {
    getStashLink({
      variables: {
        stashlinkId: state?.param?.stashLinkId || envVariables.staticStashlinkId
      }
    });
  }, [getStashLink]);

  const closeWaitListModal = () => {
    setWaitlistModalOpen(false);
  };

  if (stashlinkLoading || colinkExistLoading)
    return (
      <div className="h-screen w-screen flex justify-center items-center bg-soft-black">
        <Loader />
      </div>
    );

  return (
    <div className="bg-softblack text-white">
      <div className="bg-black">
        <MarqueeComponent text="you play = sponsors pay" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
      </div>
      <div className="p-4">
        {!isCampaignFunded && gamePlayCount < 2 ? (
          <>
            <div className="relative">
              <h1 className="text-lg text-neutral-50 font-primary font-bold mb-2">Stash with a celeb</h1>
            </div>
            <div className="relative overflow-hidden" onClick={() => history.push(`/stashlink/${stashlink?.id}`)}>
              <img
                src={generateCloudinaryImageUrl(user?.profilePhoto || "", "846", "470")}
                alt={user?.fullName}
                className="w-full h-c-245 rounded-2xl rounded-tr-3xl object-cover"
              />
              <div className="absolute p-2 bg-soft-black -top-2 -right-2 rounded-full flex justify-center items-center">
                <img
                  src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "135", "135")}
                  alt="Logo"
                  className="w-c-82 h-c-82 rounded-full"
                />
              </div>
              <div className="absolute bottom-4 left-4">
                <h2 className="text-28px font-extrabold leading-tight-29.54 capitalize text-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.32)]">{user?.fullName}</h2>
              </div>
            </div>
          </>
        ) : (
          <div className="bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg rounded-t-28 rounded-b-20 p-4 mx-auto border border-b-8 border-black">
            <div className="relative overflow-hidden">
              <img
                src={generateCloudinaryImageUrl(user?.profilePhoto || "", "622", "365")}
                alt={user?.fullName}
                className="rounded-xl rounded-bl-38 w-full h-182 object-cover"
              />
              <img
                src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "148", "148")}
                alt="Logo"
                className="absolute -bottom-2 -left-2 rounded-full w-c-74 h-c-74 border-8 border-[#30390E] object-cover"
              />
            </div>
            <div className="mt-4 text-left">
              <p className="mb-4 font-secondary text-sm text-base-white font-medium leading-tight-16.8">
                Thanks for being a part of {user?.fullName.split(" ")[0]}&apos;s community and be the first to know when the next play to
                fund campaign goes live.
              </p>
              <p className="mb-4 font-secondary text-sm text-base-white font-medium leading-tight-16.8">
                Stashrun lets you play games to fund charities, together with your friends and favorite celebs.
              </p>
              <a className="text-[#419CFF] underline font-secondary text-base">Sign up to vote</a>
            </div>
            <div className="relative mt-4 flex justify-between items-center">
              <p className="font-secondary text-base text-left text-base-white">XOXO, {user?.fullName}</p>
              <img src={Signature} alt="Signature" className="absolute right-4 -bottom-1 w-c-70 h-auto" />
            </div>
            <div className="w-full gap-[8.01px] mt-4">
              {!colinkExistData ? 
                              <Button
                              onClick={() =>
                                isUserLoggedIn
                                  ? history.push({ pathname: routes.callback, state: { from: routes.colink } })
                                  : history.push({
                                      pathname: routes.onboarding,
                                      state: { from: routes.colink, userName: user?.fullName, followerProfileImage: user?.profilePhoto }
                                    })
                              }
                              isDisabled={false}
                              title="Team up with me"
                            /> :
              !isUserJoinedWaitlist ? (
                <Button onClick={() => setWaitlistModalOpen(true)} isDisabled={false} title="Join the waitlist" />
              ) : (
                <div className="w-full mt-2 p-4 px-0">
                  <button className="w-full px-4 py-3 text-lg font-primary tracking-wider font-bold rounded-lg text-grey bg-grey-bg border border-grey flex align-center justify-center">
                    On the waitlist{" "}
                    <span className="ml-1">
                      <img src={FollowingCheck} alt="Following Check"></img>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="bg-gray-500/5 p-4 border-2 border-black rounded-3xl mt-12 text-center flex flex-col justify-center items-center">
          <img src={BulbIcon} alt="Bulb Icon" className="w-10" />
          <h3 className="font-primary text-base text-white mb-3 mt-4">Suggest something</h3>
          <p className="font-secondary text-sm text-gray-400 mb-3">
            Tell us what celebs, influencers, brands or charities you want to see on Stashrun!
          </p>
          <a
            onClick={() => mixpanel.track("suggest-clicked", { location: "home" })}
            href="https://www.stashrun.com/suggest"
            target="_blank"
            rel="noreferrer"
            className="font-secondary text-primary-l1 text-sm"
          >
            Make a suggestion
          </a>
        </div>

        <div className="mt-16 rounded-lg flex flex-col">
          <div>
            <img src={StashrunLogo} alt={StashrunLogo} className="h-[18px] w-auto" />
            <p className="opacity-80 text-neutral-50 text-sm font-normal font-secondary leading-[18.20px] mt-3">
              Play to fund charities together with <br/>friends and your favorite celebs.
            </p>
            <p className="mt-4 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none">© 2024 Stashrun Inc</p>
            <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none">Made with ❤️ from California</p>
            <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none">
              <Link onClick={() => mixpanel.track("terms-clicked")} target="_blank" to="/terms" className="underline">
                Terms
              </Link>
              {"  "}•{"  "}
              <Link onClick={() => mixpanel.track("privacy-clicked")} target="_blank" to="/privacy" className="underline">
                Privacy
              </Link>
            </p>
          </div>

          <div className="w-full -mt-40">
            <img src={bubbleGame} alt="game bubble" className="w-full max-w-md h-auto mx-auto mb-6" />
          </div>
        </div>
      </div>
      <WaitlistModal isOpen={isWaitlistModalOpen} onClose={closeWaitListModal} />
    </div>
  );
};

export default NewHomePage;
